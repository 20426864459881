import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import SEO from '../components/seo'
import '@crown-resorts/ui/dist/static/css/bundle.css'
import '../css/typography.css'
import { FeaturedHero, ContentCollection } from '@crown-resorts/ui'

const filterImagePublicURLByName = (allFile, filename) =>
  allFile?.edges?.find(({ node }) => node.name === filename)?.node?.publicURL

const getLogoData = allFile => ({
  src: filterImagePublicURLByName(allFile, 'Wedding Logo 300h-01'),
  alt: 'Crown Weddings Logo',
})
const getFeaturedHeroData = allFile => ({
  imageFrame: {
    imagePath: filterImagePublicURLByName(
      allFile,
      '191120-Crown-Sydney-General-Textures-White-Granite-Texture-2560x3840',
    ),
    altText: 'crown wedding | hero banner texture',
    focalPoint: 'center center',
  },
  image: {
    imagePath: filterImagePublicURLByName(
      allFile,
      '200715-crown-sydney-weddings-couple-b-w-2880x1500',
    ),
    altText: 'crown wedding | hero banner',
    focalPoint: '75% 50%',
  },
  trackingTag: 'widget-featuredheroimage Weddings',
  heading: 'Welcome to Crown Weddings',
  description: null,
  theme: 'cream-charcoal',
  showScrollLine: true,
  anchorTag: null,
})

const getContentCollectionData = allFile => ({
  trackingTag: 'widget-contentcollection Weddings-perth-melbourne-sydney',
  theme: 'cream',
  dividerBand: false,
  subHeading: 'Crown Weddings',
  heading:
    'At Crown, you will find everything you need to create a truly unique wedding day',
  introduction: '',
  description: null,
  ctaItems: [],
  displayType: 'card-grid-3',
  contentItems: [
    {
      displayType: 'default',
      image: {
        imagePath: filterImagePublicURLByName(
          allFile,
          '200715-Crown-Sydney-Weddings-couple-B-W-1600x1000',
        ),
        altText: 'crown wedding | crown melbourne',
        focalPoint: 'center center',
      },
      heading: 'Crown Melbourne',
      description: '',
      ctaItems: [
        {
          text: 'Explore',
          url: 'https://www.crownmelbourne.com.au/weddings',
          target: '_blank',
          ctaType: 'tertiary',
          download: false,
          icon: null,
        },
      ],
      trackingTag: 'crown-melbourne',
    },
    {
      displayType: 'default',
      image: {
        imagePath: filterImagePublicURLByName(
          allFile,
          '200715-Crown-Sydney-Weddings-Couple-B-W-1600x1200',
        ),
        altText: 'crown wedding | crown perth',
        focalPoint: 'center center',
      },
      heading: 'Crown Perth',
      description: '',
      ctaItems: [
        {
          text: 'Explore',
          url: 'https://www.crownperth.com.au/weddings/about',
          target: '_blank',
          ctaType: 'tertiary',
          download: false,
          icon: null,
        },
      ],
      trackingTag: 'crown-perth',
    },
    {
      displayType: 'default',
      image: {
        imagePath: filterImagePublicURLByName(
          allFile,
          '200715-Crown-Sydney-Weddings-Couple-Male-B-W-1600x1200-(2)',
        ),
        altText: 'crown wedding | crown sydney',
        focalPoint: 'center center',
      },
      heading: 'Crown Sydney',
      description: '',
      ctaItems: [
        {
          text: 'Explore',
          url: 'https://www.crownsydney.com.au/weddings',
          target: '_blank',
          ctaType: 'tertiary',
          download: false,
          icon: null,
        },
      ],
      trackingTag: 'crown-sydney',
    },
  ],
  totalContentItems: 3,
  contentEndpoint:
    '/api/contentcollection?type=card-grid-3&path=/Weddings-Overarching-Page-(1)/Contact-Cards',
  initialItems: 5,
  loadItems: 5,
  maxItems: 20,
  additionalItemsCta: null,
  anchorTag: null,
})

const LogoContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 160px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    height: 90px;
  }
`

const Logo = styled.img`
  height: 80px;
  @media screen and (max-width: 768px) {
    height: 50px;
  }
`

const IndexPage = ({ data }) => {
  const featuredHeroData = getFeaturedHeroData(data.allFile)
  const contentCollectionData = getContentCollectionData(data.allFile)
  const logoData = getLogoData(data.allFile)

  return (
    <div>
      <SEO title="Crown Weddings" />
      <LogoContainer>
        <Logo {...logoData} />
      </LogoContainer>
      <FeaturedHero content={featuredHeroData} />
      <ContentCollection content={contentCollectionData} />
    </div>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object,
}
export default IndexPage

export const query = graphql`
  query {
    allFile {
      edges {
        node {
          name
          publicURL
        }
      }
    }
  }
`
